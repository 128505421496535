.MarkitingLabel-module__markitingLabel {
  padding: 6px 8px;
  border-top-right-radius: 8px;
  /* background-color: #903cd4; */
  display: flex;
  gap: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 20;
  max-width: 70%;
}

body[dir="ltr"] .MarkitingLabel-module__markitingLabel {
  left: unset;
  right: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 8px;
}

.MarkitingLabel-module__markitingLabel > span {
  font-size: 9px;
  line-height: 12px;
  color: #fff;
}
