.Button-module__btn {
  height: 58px;
  width: 318px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #154f9c;
  font-size: 16px;
  line-height: 24px;
  color: #ffff;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}
.Button-module__btn:hover {
  color: white;
}

