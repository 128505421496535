.SharedPopUp-module__genralPopUp {
  position: fixed;
  z-index: 9999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #071425cf;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SharedPopUp-module__genralPopUp:not(.SharedPopUp-module__showGenralPopUp) {
  display: none;
}

.SharedPopUp-module__genralPopUp > .SharedPopUp-module__genralPopUpContent {
  width: 35%;
  position: relative;
  background-color: #ffffff;
  border-radius: 16px;
}

.SharedPopUp-module__genralPopUp > .SharedPopUp-module__genralPopUpContent > .SharedPopUp-module__genralModalbody {
  max-height: 80vh;
  overflow-y: auto;
  min-height: 200px;
}

div#SharedPopUp-module__fuelEco > .SharedPopUp-module__genralPopUpContent > .SharedPopUp-module__genralModalbody {
  max-height: 91vh;
}
span.SharedPopUp-module__closeGenralPopUp {
  background-color: transparent;
  position: absolute;
  display: block;
  width: 15px;
  height: 15px;
  right: 0;
  top: -25px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10px" height="10px" viewBox="0 0 10 10" version="1.1"><!-- Generator: sketchtool 61.2 (101010) - https://sketch.com --><title>66CF3711-9DBD-4B78-8AEB-8121E32F76B7@1</title><desc>Created with sketchtool.</desc><g id="Home-Page-3.0" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Home-Page---What-is-SyarahOnline" transform="translate(-275.000000, -126.000000)"><g id="Group-6" transform="translate(20.000000, 111.000000)"><g id="clear-24px-(11)" transform="translate(252.000000, 12.000000)"><polygon id="Path" points="0 0 16 0 16 16 0 16"/><path d="M12.2,3.80666667 C11.94,3.54666667 11.52,3.54666667 11.26,3.80666667 L8,7.06 L4.74,3.8 C4.48,3.54 4.06,3.54 3.8,3.8 C3.54,4.06 3.54,4.48 3.8,4.74 L7.06,8 L3.8,11.26 C3.54,11.52 3.54,11.94 3.8,12.2 C4.06,12.46 4.48,12.46 4.74,12.2 L8,8.94 L11.26,12.2 C11.52,12.46 11.94,12.46 12.2,12.2 C12.46,11.94 12.46,11.52 12.2,11.26 L8.94,8 L12.2,4.74 C12.4533333,4.48666667 12.4533333,4.06 12.2,3.80666667 Z" id="Path" fill="%23ffffff" fill-rule="nonzero"/></g></g></g></g></svg>');
  background-size: contain;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .SharedPopUp-module__genralPopUp > .SharedPopUp-module__genralPopUpContent {
    width: 90%;
    min-height: 50vh;
  }
  .SharedPopUp-module__owwhPadding {
    padding: 24px;
  }
}

.SharedPopUp-module__changeMinHeight {
  min-height: auto !important;
}
