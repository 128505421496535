/** mobile first */

.NewArrivalsNotificationModal-module__newArrivalModalWrapper {

}
.NewArrivalsNotificationModal-module__upperSection {
    border-radius: 16px 16px 0px 0px;
    background: #3C56D4;
    min-height: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.NewArrivalsNotificationModal-module__downSection {
    border-radius: 0 0 16px 16px;
    background: #fff;
    padding: 16px 16px 32px;
    text-align: center;
}
.NewArrivalsNotificationModal-module__thanKdownSection {
    border-radius: 0 0 16px 16px;
    background: #fff;
    padding: 16px 16px 32px;
    text-align: center;
}
.NewArrivalsNotificationModal-module__thanKdownSection p {
    margin-bottom: 0;
}
.NewArrivalsNotificationModal-module__whatsappSection {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}
.NewArrivalsNotificationModal-module__whatsText {
    color: #6C7A8D;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.NewArrivalsNotificationModal-module__modalButton {
    width: 100% !important;
    margin-top: 32px;
}
.NewArrivalsNotificationModal-module__modalHead {
    color: #154F9C;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 8px;
}
.NewArrivalsNotificationModal-module__modalText {
    color: #154F9C;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 16px;
}
/** desktop */
@media only screen and (min-width: 1024px) {
    .NewArrivalsNotificationModal-module__downSection {
        padding: 16px 80px 40px;
    }
    .NewArrivalsNotificationModal-module__modalButton {
        width: 100% !important;
    }
}
.NewArrivalsNotificationModal-module__upperSection .NewArrivalsNotificationModal-module__thanksModalIcon {
    width: 80px;
    height: 80px;
}
