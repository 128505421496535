.UnbxdCard-module__wrapper {
  display: flex;
  flex-direction: column;
  border: solid 1px #cdd1d4;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  transition: 0.3s;
  height: 100%;
  background-color: white;
}

.UnbxdCard-module__blueBorder{
  border: 2px solid #3C56D4 !important;
}

@media (max-width: 1024px) {
  .UnbxdCard-module__wrapper {
    display: flex;
    flex-direction: column;
    border: solid 1px #cdd1d4;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    transition: 0.3s;
  }
}
