.DiscountLabel-module__discountLabel {
  position: absolute;
  left: 0;
  top: 12px;
  z-index: 9;
}

body[dir="ltr"] .DiscountLabel-module__discountLabel {
  left: unset;
  right: 0;
}

.DiscountLabel-module__discountLabel > div {
  padding: 4px 8px;
  background-color: #ff6029;
  border-radius: 0 4px 4px 0;
  display: flex;
  gap: 4px;
  position: relative;
}

body[dir="ltr"] .DiscountLabel-module__discountLabel > div {
  border-radius: 4px 0 0 4px;
}

.DiscountLabel-module__discountLabel > div > div {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.DiscountLabel-module__discountLabel > div > strong,
.DiscountLabel-module__discountLabel > div > span {
  /* font-size: 10px; */
  font-size: 12px;
  line-height: 16px;
  color: #fff;
}

body[dir="rtl"] img[data-lang="en"] {
  display: none;
}

body[dir="ltr"] img[data-lang="ar"] {
  display: none;
}
