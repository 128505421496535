.CardHeader-module__cardHead {
  aspect-ratio: 4/3;
  position: relative;
  overflow: hidden;
}

.CardHeader-module__cardHead > a > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.CardHeader-module__reservedCarTag {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-radius: 4px 4px 0px 0px;
  opacity: 0.5993;
  background: #6f6e6e;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 30;
}

.CardHeader-module__reservedCarTag > span {
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 8px 0px 0px 0px;
  background: #4a4d51;
  width: 74px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
}

.CardHeader-module__FavoriteButton {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.7) !important;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  height: 32px;
  width: 32px;
  z-index: 2;
}

body[dir="ltr"] .CardHeader-module__FavoriteButton {
  right: unset;
  left: 8px;
}

body[dir="ltr"] .CardHeader-module__reservedCarTag > span {
  right: unset;
  left: 0;
}

.CardHeader-module__cardHead a {
  width: 100%;
  height: 100%;
  display: block;
}

.CardHeader-module__counter {
  border-radius: 4px;
  background: rgba(72, 72, 72, 0.6);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 2px 5px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  position: absolute;
  bottom: 14px;
  z-index: 2;
}

body[dir="rtl"] .CardHeader-module__counter {
  right: 14px;
}

body[dir="ltr"] .CardHeader-module__counter {
  left: 14px;
}

.CardHeader-module__hoverdData {
  background: rgba(72, 72, 72, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.CardHeader-module__hoverdData > span {
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #fff;
  border-radius: 4px;
}

/* .carSliderCntainer>a:not(:hover)+.hoverdData{
  display: none;
} */

/* slider css */
.CardHeader-module__singleItem {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.3s, opacity 0.3s;
}

.CardHeader-module__activeItem {
  z-index: 0;
}

.CardHeader-module__carSliderCntainer {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.CardHeader-module__carSliderCntainer > .CardHeader-module__nxtArr,
.CardHeader-module__carSliderCntainer > .CardHeader-module__prevArr {
  position: absolute;
  border-radius: 50%;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path opacity="0.6" d="M12 24C18.6176 24 24 18.6176 24 12C24 5.38235 18.6176 0 12 0C5.38235 0 0 5.38235 0 12C0 18.6176 5.38235 24 12 24Z" fill="%23484848"/><mask id="mask0_7520_3043" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><rect width="24" height="24" rx="12" fill="%231C1B1F"/></mask><g mask="url(%23mask0_7520_3043)"><path d="M14.1 12L10.2 8.10005C10.0167 7.91672 9.92505 7.68338 9.92505 7.40005C9.92505 7.11672 10.0167 6.88338 10.2 6.70005C10.3834 6.51672 10.6167 6.42505 10.9 6.42505C11.1834 6.42505 11.4167 6.51672 11.6 6.70005L16.2 11.3C16.3 11.4 16.3709 11.5084 16.4125 11.625C16.4542 11.7417 16.475 11.8667 16.475 12C16.475 12.1334 16.4542 12.2584 16.4125 12.375C16.3709 12.4917 16.3 12.6 16.2 12.7L11.6 17.3C11.4167 17.4834 11.1834 17.575 10.9 17.575C10.6167 17.575 10.3834 17.4834 10.2 17.3C10.0167 17.1167 9.92505 16.8834 9.92505 16.6C9.92505 16.3167 10.0167 16.0834 10.2 15.9L14.1 12Z" fill="white"/></g></svg>');
  background-repeat: no-repeat;
  background-position: center;
  width: 55px;
  height: 55px;
  z-index: 2;
  top: calc(50% - 55px / 2);
  cursor: pointer;
}

body[dir="rtl"] .CardHeader-module__carSliderCntainer > .CardHeader-module__prevArr {
  right: 0px;
}

body[dir="rtl"] .CardHeader-module__carSliderCntainer > .CardHeader-module__nxtArr {
  left: 0px;
  transform: rotateY(180deg);
}

body[dir="ltr"] .CardHeader-module__carSliderCntainer > .CardHeader-module__prevArr {
  left: 0px;
  transform: rotateY(180deg);
}

body[dir="ltr"] .CardHeader-module__carSliderCntainer > .CardHeader-module__nxtArr {
  right: 0px;
}

.CardHeader-module__hideArr {
  opacity: 0;
}

.CardHeader-module__singleItem:not(.CardHeader-module__activeItem):not(.CardHeader-module__videoSingleItem) {
  z-index: 0 !important;
  opacity: 0.1 !important;
}
.CardHeader-module__singleItem.CardHeader-module__videoSingleItem {
  position: relative;
}

body[dir="rtl"] .CardHeader-module__prevSlide {
  transform: translate(100%, 0) scale(1) rotateY(0);
  opacity: 1;
  pointer-events: none;
}

body[dir="ltr"] .CardHeader-module__prevSlide {
  transform: translate(-100%, 0) scale(1) rotateY(0);
  opacity: 1;
  pointer-events: none;
}

body[dir="rtl"] .CardHeader-module__nextSlide {
  transform: translate(-100%, 0) scale(1) rotateY(0);
  opacity: 1;
  pointer-events: none;
}

body[dir="ltr"] .CardHeader-module__nextSlide {
  transform: translate(100%, 0) scale(1) rotateY(0);
  opacity: 1;
  pointer-events: none;
}

body[dir="rtl"] .CardHeader-module__prevSlide.CardHeader-module__actAsLst {
  transform: translate(-100%, 0) scale(1) rotateY(0);
  opacity: 1;
  pointer-events: none;
  z-index: 99;
}

body[dir="ltr"] .CardHeader-module__prevSlide.CardHeader-module__actAsLst {
  transform: translate(100%, 0) scale(1) rotateY(0);
  opacity: 1;
  pointer-events: none;
  z-index: 99;
}

body[dir="rtl"] .CardHeader-module__nextSlide.CardHeader-module__actAsfirst {
  transform: translate(100%, 0) scale(1) rotateY(0);
  opacity: 1;
  pointer-events: none;
  z-index: 99;
}

body[dir="ltr"] .CardHeader-module__nextSlide.CardHeader-module__actAsfirst {
  transform: translate(-100%, 0) scale(1) rotateY(0);
  opacity: 1;
  pointer-events: none;
  z-index: 99;
}
/* end slider css */
