.CardFooter-module__CardFooter {
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  border-top: 1px solid #e9e9e9;
  margin-top: auto;
}

.CardFooter-module__CardFooter .CardFooter-module__tag {
  background-color: #f1f6fc;
  border-radius: 4px;
  padding: 4px 8px;
  display: flex;
  gap: 4px;
  align-items: center;
  /* font-size: 10px; */
  font-size: 12px;
  line-height: 16px;
  color: #4b5a6b;
}

/* .CardFooter .tag > img {
  width: 12px;
  height: 12px;
}

.CardFooter .tag > span {
  font-size: 10px;
  line-height: 16px;
  color: #4b5a6b;
} */
