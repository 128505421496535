.ConfirmPopup-module__popup {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
}

.ConfirmPopup-module__popup > i {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="96" height="82" viewBox="0 0 96 82" fill="none"><path opacity="0.4" d="M96 33.3968V47.9778C96 72.2794 86.4 82 62.4 82H33.6C9.6 82 0 72.2794 0 47.9778V33.3968C0 12.9929 6.768 2.88732 23.136 0.144366H24L72 0C88.992 2.50234 96 12.6561 96 33.3968Z" fill="%23FEE7E9"/><path fill-rule="evenodd" clip-rule="evenodd" d="M19.9492 40.5C19.9492 24.7608 32.6777 12 48.377 12C64.0779 12 76.8048 24.7608 76.8048 40.5C76.8048 56.2428 64.0781 69 48.377 69C32.6777 69 19.9492 56.241 19.9492 40.5ZM25.2795 40.4999C25.2795 53.2892 35.6201 63.6561 48.377 63.6561C61.1339 63.6561 71.4745 53.2892 71.4745 40.4999C71.4745 27.7106 61.1339 17.3438 48.377 17.3438C35.6201 17.3438 25.2795 27.7106 25.2795 40.4999ZM48.3788 24.499C46.3428 24.499 44.8022 25.5643 44.8022 27.2849V43.0668C44.8022 44.7892 46.3426 45.8509 48.3788 45.8509C50.3652 45.8509 51.9555 44.7448 51.9555 43.0668V27.2849C51.9553 25.6051 50.3652 24.499 48.3788 24.499ZM44.8304 52.9651C44.8304 51.0023 46.4225 49.4062 48.3787 49.4062C50.3348 49.4062 51.9249 51.0021 51.9251 52.9651C51.9251 54.9244 50.3348 56.5205 48.3787 56.5205C46.4225 56.5205 44.8304 54.9244 44.8304 52.9651Z" fill="%23FFA3AD"/></svg>');
    width: 96px;
    height: 82px;
}

.ConfirmPopup-module__popup > strong {
    color: #154f9c;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px; /* 160% */
    margin-top: 24px;
    text-align: center;
}

.ConfirmPopup-module__popup > p {
    color: #154f9c;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px; /* 175% */
    margin-top: 8px;
    margin-bottom: 24px;
    text-align: center;
}

.ConfirmPopup-module__popup > button {
    width: 318px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px; /* 150% */
    border-radius: 5px;
}

.ConfirmPopup-module__popup > button + button {
    margin-top: 16px;
}
