.CarCompare-module__checkBox {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
}

.CarCompare-module__checkBox.CarCompare-module__checkBoxEmpty {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect x="4" y="4" width="16" height="16" rx="4" stroke="%233C56D4"/></svg>');
}

.CarCompare-module__checkBox.CarCompare-module__checkBoxIncluded {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect x="4" y="4" width="16" height="16" rx="4" fill="%233C56D4" stroke="%233C56D4"/><path fill-rule="evenodd" clip-rule="evenodd" d="M11.5014 14.3328C11.3585 14.3328 11.2219 14.2727 11.1211 14.1649L9.15302 12.0597C8.94662 11.8385 8.94928 11.4837 9.15943 11.2659C9.36957 11.0492 9.70718 11.0515 9.91358 11.2727L11.4859 12.9546L14.7388 9.18371C14.9378 8.95411 15.2749 8.93783 15.4919 9.14555C15.7101 9.35383 15.7261 9.70862 15.5282 9.93879L11.8961 14.1492C11.7974 14.2637 11.6587 14.33 11.5121 14.3333C11.5083 14.3328 11.5051 14.3328 11.5014 14.3328Z" fill="white"/></svg>');
}

.CarCompare-module__checkBox.CarCompare-module__disableCheckBoxEmpty {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect x="4" y="4" width="16" height="16" rx="4" stroke="%23C0C0C0"/></svg>');
}

.CarCompare-module__ResetCompare {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><mask id="mask0_10486_1131" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><rect width="24" height="24" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_10486_1131)"><path d="M11.9998 13.4L9.0998 16.3C8.91647 16.4833 8.68314 16.575 8.3998 16.575C8.11647 16.575 7.88314 16.4833 7.6998 16.3C7.51647 16.1166 7.4248 15.8833 7.4248 15.6C7.4248 15.3166 7.51647 15.0833 7.6998 14.9L10.5998 12L7.6998 9.12495C7.51647 8.94162 7.4248 8.70828 7.4248 8.42495C7.4248 8.14162 7.51647 7.90828 7.6998 7.72495C7.88314 7.54162 8.11647 7.44995 8.3998 7.44995C8.68314 7.44995 8.91647 7.54162 9.0998 7.72495L11.9998 10.625L14.8748 7.72495C15.0581 7.54162 15.2915 7.44995 15.5748 7.44995C15.8581 7.44995 16.0915 7.54162 16.2748 7.72495C16.4748 7.92495 16.5748 8.16245 16.5748 8.43745C16.5748 8.71245 16.4748 8.94162 16.2748 9.12495L13.3748 12L16.2748 14.9C16.4581 15.0833 16.5498 15.3166 16.5498 15.6C16.5498 15.8833 16.4581 16.1166 16.2748 16.3C16.0748 16.5 15.8373 16.6 15.5623 16.6C15.2873 16.6 15.0581 16.5 14.8748 16.3L11.9998 13.4Z" fill="white"/></g></svg>');
}

.CarCompare-module__removeMeSelected {
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: absolute;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><circle cx="12" cy="12" r="11" fill="white"/><mask id="mask0_9631_7204" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><rect width="24" height="24" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_9631_7204)"><path d="M12 13.12L14.32 15.44C14.4667 15.5867 14.6533 15.66 14.88 15.66C15.1067 15.66 15.2933 15.5867 15.44 15.44C15.5867 15.2933 15.66 15.1067 15.66 14.88C15.66 14.6533 15.5867 14.4667 15.44 14.32L13.12 12L15.44 9.68C15.5867 9.53333 15.66 9.34667 15.66 9.12C15.66 8.89333 15.5867 8.70667 15.44 8.56C15.2933 8.41333 15.1067 8.34 14.88 8.34C14.6533 8.34 14.4667 8.41333 14.32 8.56L12 10.88L9.68 8.56C9.53333 8.41333 9.34667 8.34 9.12 8.34C8.89333 8.34 8.70667 8.41333 8.56 8.56C8.41333 8.70667 8.34 8.89333 8.34 9.12C8.34 9.34667 8.41333 9.53333 8.56 9.68L10.88 12L8.56 14.32C8.41333 14.4667 8.34 14.6533 8.34 14.88C8.34 15.1067 8.41333 15.2933 8.56 15.44C8.70667 15.5867 8.89333 15.66 9.12 15.66C9.34667 15.66 9.53333 15.5867 9.68 15.44L12 13.12ZM12 20C10.8933 20 9.85333 19.79 8.88 19.37C7.90667 18.95 7.06 18.38 6.34 17.66C5.62 16.94 5.05 16.0933 4.63 15.12C4.21 14.1467 4 13.1067 4 12C4 10.8933 4.21 9.85333 4.63 8.88C5.05 7.90667 5.62 7.06 6.34 6.34C7.06 5.62 7.90667 5.05 8.88 4.63C9.85333 4.21 10.8933 4 12 4C13.1067 4 14.1467 4.21 15.12 4.63C16.0933 5.05 16.94 5.62 17.66 6.34C18.38 7.06 18.95 7.90667 19.37 8.88C19.79 9.85333 20 10.8933 20 12C20 13.1067 19.79 14.1467 19.37 15.12C18.95 16.0933 18.38 16.94 17.66 17.66C16.94 18.38 16.0933 18.95 15.12 19.37C14.1467 19.79 13.1067 20 12 20ZM12 18.4C13.7867 18.4 15.3 17.78 16.54 16.54C17.78 15.3 18.4 13.7867 18.4 12C18.4 10.2133 17.78 8.7 16.54 7.46C15.3 6.22 13.7867 5.6 12 5.6C10.2133 5.6 8.7 6.22 7.46 7.46C6.22 8.7 5.6 10.2133 5.6 12C5.6 13.7867 6.22 15.3 7.46 16.54C8.7 17.78 10.2133 18.4 12 18.4Z" fill="%23484848"/></g></svg>');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  top: calc(-1 * 24px / 2);
}

body[dir="rtl"] .CarCompare-module__removeMeSelected {
  left: -5px;
}

body[dir="ltr"] .CarCompare-module__removeMeSelected {
  right: -5px;
}
